import { useTranslate } from "hooks/translate"
import { useFormContext } from "react-hook-form"
import { FormStateValues } from "state/form/types"
import { PreOwnedFormStateValues } from "state/preownedForm/types"

export const useGetFieldError = (name: keyof FormStateValues | keyof PreOwnedFormStateValues) => {
  const context = useFormContext()
  if (!context) return

  const {
    formState: { errors }
  } = context
  const { t } = useTranslate()

  const error = errors[name]
  return error ? error?.message || t(`error.${error?.type}`) : undefined
}
