import { useGetFieldError } from "app/hooks/useGetFieldError"
import { useTranslate } from "hooks/translate"
import { ReactNode } from "react"
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  RegisterOptions,
  useFormContext,
  UseFormStateReturn
} from "react-hook-form"
import { FormStateValues } from "state/form/types"
import { PreOwnedFormStateValues } from "state/preownedForm/types"
import FormError from "../Error"
import FormMessage from "../Message"
import {
  StyledField,
  StyledFieldContainer,
  StyledFieldHeader,
  StyledFieldTooltip,
  StyledFormDescription,
  StyledLabel
} from "./style"

export interface FieldRenderProps {
  field: ControllerRenderProps<FormStateValues | PreOwnedFormStateValues, keyof FormStateValues | keyof PreOwnedFormStateValues>
  fieldState: ControllerFieldState
  formState: UseFormStateReturn<FormStateValues>
}

export interface FieldProps {
  name: keyof FormStateValues | keyof PreOwnedFormStateValues
  label: string
  labelPlacement?: "default" | "postfix"
  floatingLabelEnabled?: boolean
  description?: ReactNode
  isFocused?: boolean
  rules?: RegisterOptions
  render: (props: FieldRenderProps) => React.ReactElement
  tooltip?: ReactNode
  message?: string
}

export type BaseFieldProps = Omit<FieldProps, "render">

function Field({
  name,
  label,
  labelPlacement = "default",
  floatingLabelEnabled = true,
  description,
  isFocused,
  rules,
  render,
  tooltip,
  message
}: FieldProps) {
  const { t } = useTranslate()
  const { control, watch } = useFormContext<FormStateValues | PreOwnedFormStateValues>()

  const value = watch(name)
  const isRequired = !!rules?.required
  const error = useGetFieldError(name)

  const hasFloatingLabel = labelPlacement === "default"
  const isFloating =
    floatingLabelEnabled && hasFloatingLabel && !value && !isFocused

  return (
    <StyledFieldContainer>
      <StyledField labelPlacement={labelPlacement}>
        <StyledFieldHeader>
          <StyledLabel
            htmlFor={name}
            labelPlacement={labelPlacement}
            floatingLabel={isFloating}
          >
            {t(label)}{isRequired && <sup>*</sup>} {!isRequired && <>({t("system.optional")})</>}
          </StyledLabel>
          {tooltip && (
            <StyledFieldTooltip floatingLabel={isFloating}>
              {tooltip}
            </StyledFieldTooltip>
          )}
        </StyledFieldHeader>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={render}
        />
      </StyledField>
      {description && (
        <StyledFormDescription>{description}</StyledFormDescription>
      )}
      {error && <FormError>{error}</FormError>}
      {message && <FormMessage>{message}</FormMessage>}
    </StyledFieldContainer>
  )
}

export default Field
